<script setup lang="ts">
const route = useRoute()

const title = ref<string>('')

const updateTitle = (name: typeof route.name) => {
  title.value = ''
  if (name == 'account-overview')
    title.value = 'Overview'
}

onMounted(async () => updateTitle(route.name))

watch(() => route.name, (name) => updateTitle(name))
</script>
<template>
  <div class="flex mt-8">
    <div class="w-full">
      <div class="text-lg font-semibold mt-4 md:mt-1 text-center md:text-start" v-if="title">{{ $t(title) }}</div>
      <slot />
    </div>
  </div>
</template>
